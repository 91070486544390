<script setup>
import { useAuthStore } from '~~/store/auth';

const runtimeConfig = useRuntimeConfig();
const router = useRouter();
const store = useAuthStore();

const isLoading = ref(false);
const hasError = ref(false);
const errorMsg = ref('');
const model = reactive({
  email: '',
  password: ''
});

function onSubmit(event) {
  event.preventDefault()
  
  isLoading.value = true;
  hasError.value = false;

  $fetch(`${runtimeConfig.public.api.endpoint}/login`, { 
    method: 'POST',
    body: model,
  })
  .then((response) => {
    if (!response.ok) {
      isLoading.value = false;
      hasError.value = true;
    }
    return response;
  })
  .then((response) => {
    store.login(response.data);
    router.push('/');
  })
  .catch((error) => {
    isLoading.value = false;
    hasError.value = true;
    errorMsg.value = error.message;
    console.error(error, error.message)
  });
}

</script>

<template>
  <div class="login">
    <div class="login__column login__column--form">
      <h2 class="login__title page-title">Sign in</h2>
      <form class="login__form" v-on:submit="onSubmit">
        <p class="login__form-msg" v-if="hasError">An error occurred:</p>
        <p class="login__form-msg" v-if="hasError">{{ errorMsg }}</p>
        <UiInput label="Email" v-model="model.email" />
        <UiInput type="password" label="Password" v-model="model.password" />
        <UiButton class="login__action" v-bind:isLoading="isLoading">Login</UiButton>
      </form>
    </div>
    <div class="login__column login__column--register">
      <h2 class="login__title page-title">Become a Friend</h2>
      <div class="login__text">
        <p class="login__paragraph">Don’t have an account yet?</p>
        <p class="login__paragraph">Request your access now and take the first step to Become a Friend!</p>
      </div>
      <a class="login__action ui-button ui-button--secondary ui-button--major" href="mailto:welcome@eggertandfriends.ch?subject=Become%20a%20Friend">Request Access</a>
    </div>
    
  </div>
</template>

<style lang="scss">
.login {
  display: flex;

  @include breakpoint('phone') {
    flex-direction: column;
  }
}

.login__column {
  box-sizing: border-box;
  flex: 0 0 50%;
}

.login__column--form {
  padding-right: var(--s-stack--m);
  border-right: 1px solid var(--c-text);

  @include breakpoint('phone') {
    padding-right: 0;
    padding-bottom: var(--s-stack--m);
    border-right: none;
    border-bottom: 1px solid var(--c-text);
  }
}

.login__column--register {
  padding-left: var(--s-stack--m);

  @include breakpoint('phone') {
    padding-left: 0;
    padding-top: var(--s-stack--m);
  }
}

.login__text {
  margin-top: var(--s-stack--l);
}

.login__paragraph {
  max-width: 40rem;
}

.login__action {
  display: inline-block;
  margin-top: var(--s-stack--s);
}

.login__column:nth-child(2) .login__action {
  margin-top: 2.25em;
}
</style>
